/*
    ##Device = Desktops
    ##Screen = 1281px to higher resolution desktops
  */
@media (min-width: 1281px) {
  /*CSS HERE*/ }

/*
      ##Device = Laptops, Desktops
      ##Screen = B/w 1025px to 1280px
    */
@media (min-width: 1025px) and (max-width: 1280px) {
  /*CSS HERE*/ }

/*
      ##Device = Tablets, Ipads (portrait)
      ##Screen = B/w 768px to 1024px
    */
@media (min-width: 768px) and (max-width: 1024px) {
  /*CSS HERE*/ }

/*
      ##Device = Tablets, Ipads (landscape)
      ##Screen = B/w 768px to 1024px
    */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  /*CSS HERE*/ }

.ui.form {
  color: #fff; }
  .ui.form .ui.header {
    color: #fff !important; }
  .ui.form .field label {
    color: #cccccc; }

body {
  background-color: #e5e5e5 !important; }

.no-pad {
  padding: 0 !important; }

.no-marg {
  margin: 0 !important; }

.formpad {
  padding-bottom: 20px; }

.Margins {
  margin-top: 30px !important;
  margin-left: 50px !important;
  margin-right: 50px !important;
  margin-bottom: 30px !important; }

.adminPagePadding {
  padding-right: 50px !important;
  padding-top: 30px !important; }

.navbarAlign {
  margin-left: -10px; }

.stickyPadding {
  padding-bottom: 54px; }

.BillingTablePadding {
  padding-bottom: 20px; }

.pointer {
  cursor: pointer; }

/* Searchbox Styling */
.searchbox {
  padding-top: 15px; }

.searchbox > div {
  width: 100%; }

/* Ticket "Messenger" Table CSS*/
.messengerHeaderText {
  font-size: 12px;
  font-weight: bold;
  opacity: 0.6;
  padding-bottom: 5px; }

.messengerSegment {
  word-wrap: break-word;
  max-width: 75%; }

.messengerRow,
.messengerColumn {
  box-shadow: none !important; }

/* ScrollTable "F I X" */
.scrollTable {
  width: 100%; }

.scrollTable > thead,
.scrollTable > tbody,
.scrollTable > thead > tr,
.scrollTable > tbody > tr,
.scrollTable > thead > tr > th,
.scrollTable > tbody > tr > td {
  display: block; }

.scrollTable > tbody > tr:after,
.scrollTable > thead > tr:after {
  content: " ";
  display: block;
  visibility: hidden;
  clear: both; }

.scrollTable > tbody {
  overflow-y: auto;
  max-height: 188px;
  width: calc(100% + 10px); }

@-moz-document url-prefix() {
  .scrollTable > tbody {
    width: calc(100% + 8px);
    scrollbar-width: thin; } }

.scrollTable > tbody > tr > td,
.scrollTable > thead > tr > th {
  width: 20%;
  float: left; }

/* Todo Styling */
.todoCardCross {
  float: right; }

/* Homepage Styling */
.homepageLoader {
  padding-bottom: 10px; }

.editorStyling {
  color: black; }

.ui.grid .details p {
  font-size: 1.2rem; }
  .ui.grid .details p span {
    text-align: right;
    float: right; }

.ui.grid .gridHeader {
  background: #e5e5e5;
  font-weight: bold; }

.wrapper {
  padding-bottom: 2rem; }

.footer-menu {
  border-top: 1px solid #cccccc; }

.ui.modal {
  background: #222; }
  .ui.modal > .header {
    background: #222;
    color: #fff;
    border: 1px solid #cccccc; }
  .ui.modal > .content {
    background: #555555;
    color: #fff;
    border: 1px solid #cccccc; }
  .ui.modal > .actions {
    background: #555555;
    color: #fff;
    border: 1px solid #cccccc; }

.ui.modal.summaryModal > .content {
  padding: 0; }
  .ui.modal.summaryModal > .content .ui.celled.grid.details {
    margin: 0; }
    .ui.modal.summaryModal > .content .ui.celled.grid.details p {
      font-size: 1.2rem; }
      .ui.modal.summaryModal > .content .ui.celled.grid.details p span {
        text-align: right;
        float: right; }
  .ui.modal.summaryModal > .content .ui.celled.grid {
    background: white;
    color: black !important; }
    .ui.modal.summaryModal > .content .ui.celled.grid .gridHeader {
      background: #e5e5e5;
      font-weight: bold; }
    .ui.modal.summaryModal > .content .ui.celled.grid .column {
      color: black !important; }

.splitHeader {
  display: flex;
  justify-content: space-between; }

.ui.table.selectable tr,
.ui.table.selectable td {
  cursor: pointer; }

.ticketMenuTabs {
  margin-top: 14px; }
  .ticketMenuTabs .ui.secondary.pointing.menu > a {
    justify-content: center;
    width: 33.33%; }

.billingMenuTabs .ui.secondary.pointing.menu > a {
  justify-content: center;
  width: 50%; }

.login {
  justify-content: center; }

.ui.menu .dropdown.item.dark_dropdown .menu {
  background: #1b1c1d; }
  .ui.menu .dropdown.item.dark_dropdown .menu .item {
    color: white !important; }
  .ui.menu .dropdown.item.dark_dropdown .menu .item:hover {
    background: rgba(255, 255, 255, 0.08) !important; }
